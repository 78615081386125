<template>
  <div class="conseillerBeneficiaireProfileComponent">


    <h2>Profil du bénéficiaire <v-icon name="check-circle" class="ml-1" style="color:green"/></h2>
    <div class="row">
      <div class="col-12 text-right">
        <router-link :to="'/conseiller-manager-dashboard/beneficiaire/' + beneficiaire.id">
          <small style="display: inline-flex; vertical-align: middle;">
            <v-icon name="edit" scale="1.2"/>
            <span class="ml-1">Accéder au profil global du bénéficiaire</span>
          </small>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span> Identifiant : {{beneficiaire.user.username}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span> Prénom : {{beneficiaire.user.first_name}} <span v-if="beneficiaire.other_first_names">({{beneficiaire.other_first_names}})</span></span>
      </div>
      <div class="col-md-6">
        <span> Nom : {{beneficiaire.user.last_name}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span> Date naissance : {{beneficiaire.birthdate}}</span>
      </div>
      <div class="col-md-6">
        <span> Situation : {{situationRender(beneficiaire.situation)}}</span>
      </div>
    </div>
    <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
      <div class="col-md-6">
        <span> Prénom du conjoint : {{beneficiaire.conjoint_first_name}}</span>
      </div>
      <div class="col-md-6">
        <span> Nom du conjoint : {{beneficiaire.conjoint_last_name}}</span> <br>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span> Genre : {{sexRender(beneficiaire.sex)}}</span>
      </div>
    </div>
    <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
      <div class="col-12">
        <span> Le conjoint est-il intermittent ? {{trueFalseRender(beneficiaire.is_conjoint_intermittent)}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>Numéro de téléphone : {{beneficiaire.phone_number}}</span>
      </div>
      <div class="col-md-6">
        <span> Autre numéro : {{beneficiaire.other_phone_number}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span>Adresse : {{beneficiaire.address}}, {{beneficiaire.code_postal}} {{beneficiaire.city}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <span>Activité : {{activiteRender(beneficiaire.activite)}}</span>
      </div>
      <div class="col-md-6">
        <span> Métier : {{beneficiaire.metier}}</span>
      </div>
    </div>

    <!-- CHILDREN -->
    <h2>Enfants à charge du bénéficiaire
      <v-icon v-if="beneficiaire.children.length == 0" name="times-circle" class="ml-1" style="color:red"/>
      <v-icon v-if="beneficiaire.children.length > 0" name="check-circle" class="ml-1" style="color:green"/>
    </h2>

    <b-table
      responsive class="align-middle text-center mt-4"
      headVariant="light"
      :fields="childrenRenderFields"
      :items="childrenRender"
      >
      <template v-slot:cell(birthdate)="data">
        {{$dayjs(data.item.birthdate).format('D MMM YYYY')}}
      </template>
      <template v-slot:cell(age)="data">
        <span v-if="data.item.age >= 4" class="text-danger">{{data.item.age}} ans <small> (non éligibile) </small> </span>
        <span v-else class="text-success">{{data.item.age}} ans <small> (éligible)</small></span>
      </template>
      <template v-slot:cell(duplicates)="data">
        <small v-if="data.item.duplicates.length === 0" class="text-success">Pas de doublons trouvé</small>
        <small v-else class="text-danger">
          Doublons pour les comptes :
          <span v-for="duplicate in data.item.duplicates" :key="duplicate">
            <br>{{duplicate}}
          </span>
        </small>
      </template>
    </b-table>
  </div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'
import { dateOperations } from '@/mixins/utilMixins'
export default {
  name: 'conseillerBeneficiaireProfileComponent',
  props: {
    beneficiaire: Object
  },
  mixins: [
    renderMixins,
    dateOperations
  ],
  data: function () {
    return {

      childrenRenderFields: [
        { key: "first_name", label: "Prénom", _style: "width: 10%;"},
        { key: "last_name", label: "Nom", _style: "width: 10%;"},
        { key: "birthdate", label: "Date de naissance", _style: "width: 10%;"},
        { key: "age", label: "Age"},
        { key: "duplicates", label: "Doublon"},
      ],

    }
  },
  computed: {
    childrenRender () {
      if (this.beneficiaire.children.length == 0) {
        return []
      }
      else {
        var final_array = []
        for (var i = 0; i < this.beneficiaire.children.length; i++) {
          if (this.beneficiaire.children[i].is_active) {
            final_array.push(
              {
                'id': this.beneficiaire.children[i].id,
                'first_name': this.beneficiaire.children[i].first_name,
                'last_name': this.beneficiaire.children[i].last_name,
                'birthdate': this.beneficiaire.children[i].birthdate,
                'age': this.calculateAge(this.beneficiaire.children[i].birthdate),
                'duplicates': this.beneficiaire.children[i].duplicate_child_accounts
              }
            )
          }
        }
        return final_array
      }
    }
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
